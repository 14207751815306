import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import ButtonArrow from "./ButtonArrow";

const useStyles = makeStyles((theme) => ({
  itemContainer: {
    marginLeft: "6em",
    marginBottom: "1.5em",
  },
}));

const ConsultingPage = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography
          variant="h2"
          gutterBottom
          style={
            ({ marginLeft: matchesSM ? 0 : "5em" },
            { marginTop: "2em", marginLeft: "2em" })
          }
        >
          Consulting
        </Typography>
        <Typography
          variant="body1"
          paragraph
          style={
            ({ marginLeft: matchesSM ? 0 : "5em" },
            {
              marginTop: "2em",
              marginLeft: "4em",
              marginRight: "4em",
              marginBottom: "3em",
            })
          }
        >
          Our team will spend time to understand the existing workings of not
          just your ICT Infrastructure but your organization. This will help
          them to identify potential areas for improvement and development as
          well as make recommendations for how they could be improved and
          developed. We provide services along the following axes:
        </Typography>
      </Grid>
      <Grid item>
        <Grid container>
          <Grid item className={classes.itemContainer}>
            <ButtonArrow
              height={30}
              width={40}
              fill={theme.palette.common.blue}
            />
          </Grid>
          <Typography>Strategy & Transformation</Typography>
        </Grid>
        <Grid container>
          <Grid item className={classes.itemContainer}>
            <ButtonArrow
              height={30}
              width={40}
              fill={theme.palette.common.blue}
            />
          </Grid>
          <Typography>Enterprise Architecture Design</Typography>
        </Grid>
        <Grid container>
          <Grid item className={classes.itemContainer}>
            <ButtonArrow
              height={30}
              width={40}
              fill={theme.palette.common.blue}
            />
          </Grid>
          <Typography>IT Programme & Project Management</Typography>
        </Grid>
        <Grid container>
          <Grid item className={classes.itemContainer}>
            <ButtonArrow
              height={30}
              width={40}
              fill={theme.palette.common.blue}
            />
          </Grid>
          <Typography>Information & Systems Security</Typography>
        </Grid>
        <Grid container>
          <Grid item className={classes.itemContainer}>
            <ButtonArrow
              height={30}
              width={40}
              fill={theme.palette.common.blue}
            />
          </Grid>
          <Typography style={{ marginBottom: "20em" }}>
            IT Solutions Delivery
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConsultingPage;
