import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  networkHeading: {
    marginTop: "2em",
    marginLeft: "2em",
    marginBottom: "0.5em",
  },
  networkParagraph: {
    marginLeft: "4em",
    marginRight: "4em",
  },
}));

const NetworkPage = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography
          variant="h2"
          gutterBottom
          style={
            ({ marginLeft: matchesSM ? 0 : "5em" },
            { marginTop: "2em", marginLeft: "2em", marginBottom: "1.5em" })
          }
        >
          Networking
        </Typography>
        <Typography variant="h3" className={classes.networkHeading}>
          Cabling
        </Typography>
        <Typography
          variant="body1"
          paragraph
          className={classes.networkParagraph}
          style={{ marginBottom: "2em" }}
        >
          If you’re constructing a new building, moving offices, expanding your
          workforce, looking to introduce wireless networks or you just want
          your installation maintained our engineers can help
        </Typography>
        <Typography
          variant="body1"
          paragraph
          className={classes.networkParagraph}
        >
          With a wealth of experience in installing structured cabling
          (including wireless) our engineers will work with you to understand
          your requirements and deliver a cabling solution that meets those
          needs
        </Typography>
        <Typography variant="h3" className={classes.networkHeading}>
          Telephony
        </Typography>
        <Typography
          variant="body1"
          paragraph
          className={classes.networkParagraph}
        >
          With a wealth of experience our engineers will work with you in
          choosing the right technology for your phone system and to understand
          how you want it installed and configured whether that be; hunt groups,
          voicemails, out of office hours, call forwarding or something else.
        </Typography>
        <Typography variant="h3" className={classes.networkHeading}>
          Surveillance/CCTV
        </Typography>
        <Typography
          variant="body1"
          paragraph
          className={classes.networkParagraph}
          style={{ marginBottom: "20em" }}
        >
          We offer a comprehensive portfolio of CCTV solutions for Home, SMB and
          Large Corporates. We can provide standard format solutions or tailor
          made system design for more challenging applications. We can provide
          and install a variety of CCTV security cameras to meet all budgetary
          and functionality requirements, including Analogue, HD and IP and our
          experienced team are on hand to help you decide which system is most
          suited to your requirements.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NetworkPage;
