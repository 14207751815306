import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import ButtonArrow from "./ButtonArrow";

const useStyles = makeStyles((theme) => ({
  itemContainer: {
    marginLeft: "6em",
    marginBottom: "1.5em",
  },
}));

const SoftwarePage = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography
          variant="h2"
          gutterBottom
          style={
            ({ marginLeft: matchesSM ? 0 : "5em" },
            { marginTop: "2em", marginLeft: "2em" })
          }
        >
          Software
        </Typography>
        <Typography
          variant="body1"
          paragraph
          style={
            ({ marginLeft: matchesSM ? 0 : "5em" },
            {
              marginTop: "2em",
              marginLeft: "4em",
              marginRight: "4em",
              marginBottom: "3em",
            })
          }
        >
          We have full set of services around development, hosting and
          maintenance of complex business-critical applications. Our experts
          build, test, deploy, protect, manage, migrate and optimize
          enterprise-scale digital solutions ensuring they’re always up and
          running
        </Typography>
      </Grid>
      <Grid item>
        <Grid container>
          <Grid item className={classes.itemContainer}>
            <ButtonArrow
              height={30}
              width={40}
              fill={theme.palette.common.blue}
            />
          </Grid>
          <Typography>Software consulting</Typography>
        </Grid>
        <Grid container>
          <Grid item className={classes.itemContainer}>
            <ButtonArrow
              height={30}
              width={40}
              fill={theme.palette.common.blue}
            />
          </Grid>
          <Typography>Software development</Typography>
        </Grid>
        <Grid container>
          <Grid item className={classes.itemContainer}>
            <ButtonArrow
              height={30}
              width={40}
              fill={theme.palette.common.blue}
            />
          </Grid>
          <Typography>Software integration</Typography>
        </Grid>
        <Grid container>
          <Grid item className={classes.itemContainer}>
            <ButtonArrow
              height={30}
              width={40}
              fill={theme.palette.common.blue}
            />
          </Grid>
          <Typography>Software Hosting</Typography>
        </Grid>
        <Grid container>
          <Grid item className={classes.itemContainer}>
            <ButtonArrow
              height={30}
              width={40}
              fill={theme.palette.common.blue}
            />
          </Grid>
          <Typography style={{ marginBottom: "20em" }}>
            Software Maintenance
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SoftwarePage;
