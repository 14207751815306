import { createMuiTheme } from "@material-ui/core/styles";

const synBlue = "#0B72B9";
const synOrange = "#FFBA60";
const synGrey = "#868686";

const theme = createMuiTheme({
  palette: {
    common: {
      blue: `${synBlue}`,
      orange: `${synOrange}`,
    },
    primary: {
      main: `${synBlue}`,
    },
    secondary: {
      main: `${synOrange}`,
    },
  },
  typography: {
    h2: {
      fontFamily: "Raleway",
      fontWeight: 700,
      fontSize: "2.5rem",
      color: `${synBlue}`,
      lineHeight: 1.5,
    },
    h3: {
      fontFamily: "Pacifico",
      fontSize: "2.5rem",
      color: `${synBlue}`,
    },
    h4: {
      fontFamily: "Raleway",
      fontSize: "1.75rem",
      color: `${synBlue}`,
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: "1.25rem",
      fontWeight: 300,
      color: `${synGrey}`,
    },
    subtitle2: {
      color: "white",
      fontSize: "1.25rem",
      fontWeight: 300,
    },
    body1: {
      fontSize: "1.25rem",
      color: synGrey,
      fontWeight: 300,
    },
    learnButton: {
      borderColor: `${synBlue}`,
      color: `${synBlue}`,
      borderWidth: 2,
      textTransform: "none",
      borderRadius: 50,
      fontFamily: "Roboto",
      fontWeight: "bold",
    },
    tab: {
      fontFamily: "Raleway",
      textTransform: "none",
      fontSize: "1rem",
      fontWeight: 700,
    },
    estimate: {
      fontFamily: "Pacifico",
      fontSize: "1rem",
      textTransform: "none",
      color: "white",
    },
  },
  overrides: {
    MuiInputLabel: {
      root: {
        color: synBlue,
        fontSize: "1rem",
      },
    },
    MuiInput: {
      root: {
        color: synGrey,
        fontSize: "1rem",
      },
      underline: {
        "&:before": {
          borderBottom: `2px solid ${synBlue}`,
        },
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottom: `2px solid ${synBlue}`,
        },
      },
    },
  },
});
export default theme;
