import React from "react";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  aboutHeading: {
    marginTop: "2em",
    marginBottom: "0.5em",
    marginLeft: "5.5em",
  },

  aboutParagraph: {
    marginLeft: "7.6em",
    marginRight: "7.6em",
  },

  missionStatement: {
    marginLeft: "7.6em",
    marginRight: "7.6em",
    marginBottom: "10em",
  },
}));

const AboutPage = (props) => {
  const classes = useStyles();
  return (
    <Grid container direction="column">
      <Grid item>
        <Typography
          variant="h4"
          className={classes.aboutHeading}
          style={{ marginTop: "4em" }}
        >
          Who we are
        </Typography>
        <Typography
          variant="body1"
          paragraph
          className={classes.aboutParagraph}
        >
          We are a technology company that believe on a team work. We work hand
          in hand with our clients to come up with tailored solutions. The
          combined power of us and client working together is greater than the
          total power archived each working separately and that is what synergy
          is all about.
        </Typography>
      </Grid>

      <Grid item>
        <Typography variant="h4" className={classes.aboutHeading}>
          History
        </Typography>
        <Typography
          variant="body1"
          paragraph
          className={classes.aboutParagraph}
        >
          Incorporated in Tanzania, we’re ready to get our hands on the world’s
          business problems. It all started with one question: Why aren’t all
          businesses using available technology? There are many different
          answers to that question: economic barriers, social barriers,
          educational barriers, and sometimes institutional barriers. We aim to
          be a powerful force in overcoming these obstacles. Recent developments
          in software engineering and computing power, compounded by the
          proliferation of tablets and smart phones, has opened up infinite
          worlds of possibilities. Things that have always been done by hand can
          now be done digitally and automatically, and completely new methods of
          interaction are created daily. Taking full advantage of these
          advancements is the name of the game. All this change can be a lot to
          keep up with, and that’s where we come in.
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h4" className={classes.aboutHeading}>
          Team
        </Typography>
        <Typography
          variant="body1"
          paragraph
          className={classes.aboutParagraph}
        >
          Synergy Technologies Limited is managed and operated by highly
          experienced and skilled professionals who have been in the industry
          for over 15 years covering all major sectors of Information Technology
          including but not limited to Telecommunication, Cloud computing,
          Enterprise applications, Data centres, Mobile platforms, End users
          computing, Backup and recovery etc.
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h4" className={classes.aboutHeading}>
          Vision
        </Typography>
        <Typography
          variant="body1"
          paragraph
          className={classes.aboutParagraph}
        >
          Synergy Technologies Limited vision is to become a preferred choice
          for companies and business around the world in the acquisition and
          assimilation of Information Technologies
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h4" className={classes.aboutHeading}>
          Mission
        </Typography>
        <Typography
          variant="body1"
          paragraph
          className={(classes.aboutParagraph, classes.missionStatement)}
        >
          Synergy Technologies Limited is dedicated to the highest quality of
          web applications and network services delivered with creativity,
          originality and innovation to revolutionize business processes around
          the world
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AboutPage;
