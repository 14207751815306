import React from "react";
import { Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import ButtonArrow from "./ButtonArrow";
import softwareImage from "../assets/software.png";
import consultingImage from "../assets/consulting.png";
import networkImage from "../assets/network.png";
import websiteImage from "../assets/website.png";

const useStyles = makeStyles((theme) => ({
  learnButton: {
    ...theme.typography.learnButton,
    fontSize: "0.7rem",
    height: 35,
    padding: 5,
    [theme.breakpoints.down("sm")]: {
      marginBottom: "2em",
    },
  },
  celebrationText: {
    fontFamily: "Pacifico",
    fontSize: "1.5rem",
    color: theme.palette.common.orange,
  },
  subtitle: {
    marginBottom: "1em",
  },
  icon: {
    marginLeft: "2em",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  image: {
    marginLeft: "2em",
    width: "80%",
    height: "80%",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  serviceContainer: {
    marginTop: "5em",
    marginBottom: "5em",
    [theme.breakpoints.down("sm")]: {
      padding: 25,
    },
  },
  itemContainer: {
    maxWidth: "60em",
  },
  serviceHeading: {
    marginBottom: "1.5em",
  },
}));

const ServicePage = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography
          align={matchesSM ? "center" : undefined}
          variant="h2"
          gutterBottom
          style={
            ({ marginLeft: matchesSM ? 0 : "5em" },
            { marginTop: "2em", marginLeft: "2em" })
          }
        >
          Services
        </Typography>
      </Grid>
      <Grid item>
        {/*-----Consulting Section-----*/}
        <Grid
          container
          alignItems="center"
          justify={matchesSM ? "center" : undefined}
          direction="row"
          wrap="nowrap"
          className={classes.serviceContainer}
        >
          <Grid
            item
            className={classes.itemContainer}
            style={{
              marginLeft: matchesSM ? 0 : "5em",
              textAlign: matchesSM ? "center" : undefined,
            }}
          >
            <Typography variant="h4" className={classes.serviceHeading}>
              Consulting Services
            </Typography>
            <Typography variant="body1" paragraph>
              In the current highly competitive and dynamic business
              environment, Information and Communication Technologies (ICT) are
              more than ever a critical enabler for an organisation’s success,
              since it constitutes a source of competitive advantage and an
              important tool in the effort to automate and streamline business
              operations.
            </Typography>
            <Typography variant="body1" paragraph>
              We provides ICT Consulting services that help organizations to put
              ICT assets at the service of their business needs and strategic
              vision. From the small private enterprises to major public
              entities, finding the best way to integrate IT systems and align
              them with the business needs and strategic goals, is central to an
              organisation’s success.
            </Typography>
            <Button
              component={Link}
              to="/synergyconsulting"
              variant="outlined"
              className={classes.learnButton}
            >
              <span style={{ marginRight: 10 }}>Learn More</span>
              <ButtonArrow
                height={15}
                width={15}
                fill={theme.palette.common.blue}
              />
            </Button>
          </Grid>
          <Grid item>
            <img
              className={classes.image}
              src={consultingImage}
              alt="consulting icon"
            />
          </Grid>
        </Grid>
        {/*-----Networks Section-----*/}
        <Grid
          container
          alignItems="center"
          justify={matchesSM ? "center" : undefined}
          direction="row"
          wrap="nowrap"
          className={classes.serviceContainer}
        >
          <Grid item>
            <img
              className={classes.image}
              src={networkImage}
              alt="network icon"
            />
          </Grid>

          <Grid
            item
            className={classes.itemContainer}
            style={{
              marginLeft: matchesSM ? 0 : "5em",
              textAlign: matchesSM ? "center" : undefined,
            }}
          >
            <Typography variant="h4" className={classes.serviceHeading}>
              Network Services
            </Typography>
            <Typography variant="body1" paragraph>
              We know that your business relies on efficient and reliable
              communications systems for networks, telephony, CCTV and ICT
              systems. We are a multi-disciplined installer of infrastructure
              solutions, providing a comprehensive range of structured cabling,
              telephony, CCTV, and technical installation services.
            </Typography>{" "}
            <Typography variant="body1" paragraph>
              We can handle any size project whether it’s installing a new
              fiber-optic backbone to connect your Ethernet devices,
              reconfiguring an existing voice & data network or expanding your
              existing building low-voltage infrastructure
            </Typography>
            <Button
              component={Link}
              to="/synergynetworking"
              variant="outlined"
              className={classes.learnButton}
            >
              <span style={{ marginRight: 10 }}>Learn More</span>
              <ButtonArrow
                height={15}
                width={15}
                fill={theme.palette.common.blue}
              />
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {/*-----Application development Section-----*/}
        <Grid
          container
          alignItems="center"
          justify={matchesSM ? "center" : "flex-end"}
          direction="row"
          wrap="nowrap"
          className={classes.serviceContainer}
        >
          <Grid
            item
            className={classes.itemContainer}
            style={{
              marginLeft: matchesSM ? 0 : "5em",
              textAlign: matchesSM ? "center" : undefined,
            }}
          >
            <Typography variant="h4" className={classes.serviceHeading}>
              Software Development
            </Typography>
            <Typography variant="body1" paragraph>
              We develop reliable and scalable software solutions for any
              device, Operating System, and browser. We bring together deep
              industry expertise and the latest IT advancements to deliver
              custom solutions and products that perfectly fit the needs and
              behaviour of their users.
            </Typography>
            <Typography variant="body1" paragraph>
              Leverage our expertise in enterprise software development, API
              integration, modernising legacy systems, and consolidating app
              portfolios
            </Typography>
            <Button
              component={Link}
              to="/synergysoftware"
              variant="outlined"
              className={classes.learnButton}
            >
              <span style={{ marginRight: 10 }}>Learn More</span>
              <ButtonArrow
                height={15}
                width={15}
                fill={theme.palette.common.blue}
              />
            </Button>
          </Grid>
          <Grid item>
            <img
              className={classes.image}
              src={softwareImage}
              alt="software icon"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {/*-----Website development Section-----*/}
        <Grid
          container
          alignItems="center"
          justify={matchesSM ? "center" : undefined}
          direction="row"
          wrap="nowrap"
          className={classes.serviceContainer}
        >
          <Grid item>
            <img
              className={classes.image}
              src={websiteImage}
              alt="website icon"
            />
          </Grid>
          <Grid
            item
            className={classes.itemContainer}
            style={{
              marginLeft: matchesSM ? 0 : "5em",
              textAlign: matchesSM ? "center" : undefined,
            }}
          >
            <Typography variant="h4" className={classes.serviceHeading}>
              Website Development
            </Typography>
            <Typography variant="body1" paragraph>
              Having a website is a necessity in today’s business world. They
              give you one central, public location to let people know who you
              are, what you do, and why you’re the best at it.
            </Typography>
            <Typography variant="body1" paragraph>
              From simply having your hours posted to having a full fledged
              online store, making yourself as accessible as possible to users
              driving growth and enabling you to reach new customers.
            </Typography>
            <Button
              component={Link}
              to="/synergywebsite"
              variant="outlined"
              className={classes.learnButton}
            >
              <span style={{ marginRight: 10 }}>Learn More</span>
              <ButtonArrow
                height={15}
                width={15}
                fill={theme.palette.common.blue}
              />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ServicePage;
