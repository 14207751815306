import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  itemContainer: {
    marginTop: "10em",
    marginBottom: "20em",
  },
}));

const ContactPage = (props) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className={classes.itemContainer}
    >
      <Grid item>
        <Typography variant="h2" gutterBottom style={{ marginBottom: "1.5em" }}>
          CONTACT US
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h2" gutterBottom>
          MOROGORO
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Plot # 459, Kigurunyembe Medium Density, P. O Box 2379, Morogoro,
          Tanzania.
        </Typography>
      </Grid>
      <Grid item>
        <Typography>+255 73 423 5978</Typography>
      </Grid>
      <Grid item>
        <Typography>+255 78 435 0620</Typography>
      </Grid>
      <Grid item>
        <Typography>+255 67 449 1577</Typography>
      </Grid>
      <Grid item>
        <Typography>info@synergytechnologies.co.tz</Typography>
      </Grid>
      <Grid item>
        <Typography style={{ marginBottom: "2em" }}>
          www.synergytechnologies.co.tz
        </Typography>
      </Grid>

      <Grid item>
        <Typography variant="h2" gutterBottom>
          ARUSHA
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          2nd Floor, Ngorongoro Tourism Centre, Makongoro/Goliondoi Road,
          Arusha, Tanzania.
        </Typography>
      </Grid>
      <Grid item>
        <Typography>+255 75 996 5348</Typography>
      </Grid>
      <Grid item>
        <Typography>+255 67 449 1577</Typography>
      </Grid>
      <Grid item>
        <Typography>sales@synergytechnologies.co.tz</Typography>
      </Grid>
      <Grid item>
        <Typography>www.synergytechnologies.co.tz</Typography>
      </Grid>
    </Grid>
  );
};
export default ContactPage;
